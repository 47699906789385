import React from 'react';
import AppRouter from './router/AppRouter';
import "./App.css";
import "./output.css";

const App = () => {
  return (
    <AppRouter />
  );
};

export default App;
