import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Label } from "../components/ui/label";
import { AlertCircle } from "lucide-react";

const Home = () => {
  const [caseIdOrUrl, setCaseIdOrUrl] = useState("");
  const [emailMode, setEmailMode] = useState("first");
  const [isLiveMode, setIsLiveMode] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const extractCaseId = (input) => {
    const match = input.match(/\/([a-zA-Z0-9]{15,18})(?:\/|$)/);
    return match ? match[1] : input;
  };

  const handleFetchCase = () => {
    const extractedCaseId = extractCaseId(caseIdOrUrl);
    if (extractedCaseId) {
      navigate(`/case/${extractedCaseId}`, { state: { emailMode, isLiveMode } });
    } else {
      setError("Invalid Case ID or URL");
    }
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <Card className="mb-4">
        <CardHeader>
          <CardTitle>Salesforce Case Emails</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex space-x-2">
              <Input
                placeholder="Enter Case ID or Salesforce URL"
                value={caseIdOrUrl}
                onChange={(e) => setCaseIdOrUrl(e.target.value)}
              />
              <Button onClick={handleFetchCase}>Fetch Case</Button>
            </div>
            <div className="flex items-center space-x-2">
              <Label htmlFor="email-mode" className="text-sm whitespace-nowrap">
                Email Mode:
              </Label>
              <select
                id="email-mode"
                value={emailMode}
                onChange={(e) => setEmailMode(e.target.value)}
                className="w-[180px] text-sm border border-gray-300 rounded-md p-2 bg-white"
              >
                <option value="first">First Message</option>
                <option value="thread">Entire Thread</option>
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <Label htmlFor="live-mode">Live Mode</Label>
              <input
                type="checkbox"
                id="live-mode"
                checked={isLiveMode}
                onChange={(e) => setIsLiveMode(e.target.checked)}
              />
            </div>
            {error && (
              <div className="flex items-center space-x-2 text-red-500">
                <AlertCircle className="h-5 w-5" />
                <span>{error}</span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Home;
