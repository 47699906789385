import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { format } from "date-fns";
import { Mail, ArrowDownLeft, ArrowUpRight } from "lucide-react";

const EmailAccordion = ({ messages }) => {
  const [activeItem, setActiveItem] = useState(null);
  const accordionRef = useRef(null);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM d, yyyy HH:mm");
  };

  useEffect(() => {
    if (activeItem && accordionRef.current) {
      const activeElement = accordionRef.current.querySelector(`[data-state="open"]`);
      if (activeElement) {
        const container = accordionRef.current.closest('.overflow-y-auto');
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const activeElementRect = activeElement.getBoundingClientRect();
          const offset = activeElementRect.top - containerRect.top;
          container.scrollTop += offset;
        }
      }
    }
  }, [activeItem]);

  return (
    <Accordion 
      type="single" 
      collapsible 
      className="w-full" 
      onValueChange={setActiveItem}
      ref={accordionRef}
    >
      {messages.map((message, index) => (
        <AccordionItem value={`item-${index}`} key={message.Id} className="border-b">
          <AccordionTrigger className="sticky top-0 z-10 bg-white px-4 py-2 hover:bg-gray-100 w-full">
            <div className="flex justify-between items-center w-full">
              <div className="font-bold truncate mr-2">{message.FromAddress}</div>
              <div className="flex items-center flex-shrink-0">
                {message.Incoming ? (
                  <ArrowDownLeft className="text-green-500 mx-2" />
                ) : (
                  <ArrowUpRight className="text-blue-500 mx-2" />
                )}
                <div className="text-sm text-gray-500">
                  {formatDate(message.MessageDate)}
                </div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="prose max-w-none p-4">
              {message.HtmlBody ? (
                <div dangerouslySetInnerHTML={{ __html: message.HtmlBody }} />
              ) : (
                <pre className="whitespace-pre-wrap">{message.TextBody}</pre>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default EmailAccordion;
