import React, { useState } from 'react';
import { Button } from "./ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";
import EmailSummaryContent from './EmailSummaryContent';

const EmailSummary = ({ summary, loading }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  if (loading) {
    return <div className="mb-4">Loading summary...</div>;
  }

  if (!summary) {
    return null; // Don't render anything if summary is undefined
  }

  const handleItemClick = (itemType, emailId, lineId) => {
    const event = new CustomEvent('summaryItemClicked', {
      detail: { emailId, itemType, lineId },
      bubbles: true,
      cancelable: true
    });
    document.dispatchEvent(event);
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">Summary</h3>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </Button>
      </div>
      {isExpanded && (
        <EmailSummaryContent summary={summary} handleItemClick={handleItemClick} />
      )}
    </div>
  );
};

export default EmailSummary;
