import React from "react";
import { Button } from "./ui/button";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageList = ({ messages = [], handleFeedback, isTyping }) => {
  const getInitials = (email) => {
    return email.split("@")[0].slice(0, 2).toUpperCase();
  };

  const renderMessage = (message) => {
    return (
      <div key={message.id} className="flex items-start mb-4">
        <div
          className={`w-10 h-10 rounded-full ${
            message.isAI ? "bg-green-500" : "bg-blue-500"
          } flex items-center justify-center text-white font-bold mr-4 flex-shrink-0`}
        >
          {getInitials(message.sender)}
        </div>
        <div className="prose bg-gray-100 rounded-lg p-3 flex-grow">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {message.content}
          </ReactMarkdown>
          {message.isAI && message.feedback === null && (
            <div className="mt-2 flex space-x-2">
              <Button
                size="sm"
                variant="outline"
                onClick={() => handleFeedback(message.id, true)}
              >
                <ThumbsUp className="h-4 w-4" />
              </Button>
              <Button
                size="sm"
                variant="outline"
                onClick={() => handleFeedback(message.id, false)}
              >
                <ThumbsDown className="h-4 w-4" />
              </Button>
            </div>
          )}
          {message.isAI && message.feedback !== null && (
            <div className="mt-2">
              <Button
                size="sm"
                variant="default"
                className="pointer-events-none"
              >
                {message.feedback ? (
                  <ThumbsUp className="h-4 w-4" />
                ) : (
                  <ThumbsDown className="h-4 w-4" />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4 pt-4">
      {messages.map((message) => renderMessage(message))}
      {isTyping && (
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '200ms' }}></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '400ms' }}></div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
