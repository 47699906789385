import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/ui/card";
import { ScrollArea } from "../components/ui/scroll-area";
import io from "socket.io-client";
import RequesterInfo from "../components/RequesterInfo";
import MessageList from "../components/MessageList";
import EmailSummary from "../components/EmailSummary";
import OriginalEmail from "../components/OriginalEmail";
import ActionItems from "../components/ActionItems";

const Case = () => {
  const { caseId } = useParams();

  const [messages, setMessages] = useState([]);
  const [caseInfo, setCaseInfo] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [emailSummary, setEmailSummary] = useState("");
  const [loadingStates, setLoadingStates] = useState({
    caseDetails: true,
    emailThread: true,
    caseInfo: true,
    emailSummary: true,
    actors: true,
    actionItems: true,
  });
  const [error, setError] = useState(null);
  const scrollAreaRef = useRef(null);

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL || "http://0.0.0.0:5000";
    const newSocket = io(API_URL);
    setSocket(newSocket);

    newSocket.on("case_info", (data) => {
      console.log("Received case info:", data.case);
      setCaseInfo(data.case);
      setLoadingStates((prev) => ({ ...prev, caseInfo: false }));
      newSocket.emit("summarize_case", { caseId: data.case.Id });
    });

    newSocket.on("response", (data) => {
      console.log("response message on socket", data);
      setMessages((prevMessages) => [...prevMessages, ...data.messages]);
    });

    newSocket.on("email_summary", (data) => {
      console.log("Received email summary:", data.summary);
      setEmailSummary(data.summary);
      setLoadingStates((prev) => ({
        ...prev,
        emailSummary: false,
        actors: false,
        actionItems: false,
      }));
    });

    newSocket.on("error", (data) => {
      console.error("Received error:", data.message);
      setError(data.message);
      setLoadingStates({
        caseInfo: false,
        emailSummary: false,
        actors: false,
        actionItems: false,
      });
    });

    newSocket.on("typing_status", (data) => {
      setIsTyping(data.is_typing);
    });

    return () => {
      newSocket.off("case_info");
      newSocket.off("response");
      newSocket.off("email_summary");
      newSocket.off("typing_status");
      newSocket.off("error");
      newSocket.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("init", { caseId, mode: "all" });
    }
  }, [socket, caseId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector(
        "[data-radix-scroll-area-viewport]"
      );
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    socket.emit("message", { message: newMessage, caseId });
    setNewMessage("");
  };

  const handleFeedback = async (messageId, isPositive) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message.id === messageId
          ? { ...message, feedback: isPositive }
          : message
      )
    );
  };

  const handleItemClick = (itemType, emailId, lineId) => {
    const event = new CustomEvent("summaryItemClicked", {
      detail: { emailId, itemType, lineId },
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(event);
  };

  return (
    <div className="p-4 max-w-7xl mx-auto">
      <div className="space-y-4">
        <RequesterInfo
          actors={emailSummary?.actors}
          loading={loadingStates.actors}
        />

        <EmailSummary
          summary={emailSummary}
          loading={loadingStates.emailSummary}
        />

        <div className="flex space-x-4">
          <div className="w-2/3 space-y-4">
            {caseInfo &&
              caseInfo.EmailMessages &&
              caseInfo.EmailMessages.length > 0 && (
                <OriginalEmail
                  emailMessages={caseInfo.EmailMessages}
                  loading={loadingStates.caseInfo}
                />
              )}

            <Card>
              <CardContent>
                <ScrollArea className="h-[400px] pr-4" ref={scrollAreaRef}>
                  <MessageList
                    messages={messages.slice(1)}
                    handleFeedback={handleFeedback}
                    isTyping={isTyping}
                  />
                </ScrollArea>
              </CardContent>
            </Card>

            <Card>
              <CardContent className="pt-4">
                <div className="flex space-x-2">
                  <Input
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <Button onClick={sendMessage}>Send</Button>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="w-1/3 space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Action Items</CardTitle>
              </CardHeader>
              <CardContent>
                <ActionItems
                  actionItems={emailSummary?.action_items}
                  handleItemClick={handleItemClick}
                  loading={loadingStates.actionItems}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Case;
