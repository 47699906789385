import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { Button } from "./ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

const RequesterInfo = ({ actors, loading }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (loading) {
    return <div>Loading requester information...</div>;
  }

  if (!actors || actors.length === 0) {
    return <div>No requester information available.</div>;
  }

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">Actors</h3>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </Button>
      </div>
      {isExpanded && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/4">Name</TableHead>
              <TableHead className="w-1/4">Email</TableHead>
              <TableHead className="w-1/4">Role</TableHead>
              <TableHead className="w-1/4">Company</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {actors.map((actor, index) => (
              <TableRow key={index}>
                <TableCell className="py-2">{actor.name}</TableCell>
                <TableCell className="py-2">{actor.email}</TableCell>
                <TableCell className="py-2">{actor.role}</TableCell>
                <TableCell className="py-2">{actor.company}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default RequesterInfo;
