import React from 'react';

const EmailSummaryContent = ({ summary, handleItemClick }) => {
  if (!summary) {
    return null;
  }

  return (
    <div className="space-y-4">
      {summary.main_points && (
        <div>
          <p 
            onClick={() => handleItemClick('main_point', summary.main_points.email_id, summary.main_points.line_id.toString())}
            className="cursor-pointer group relative pl-10"
          >
            {summary.main_points.content}
          </p>
        </div>
      )}
      {summary.sentiment && (
        <div>
          <h3 className="font-semibold">Sentiment:</h3>
          <p>{summary.sentiment}</p>
        </div>
      )}
    </div>
  );
};

export default EmailSummaryContent;
