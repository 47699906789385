import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://0.0.0.0:5000";

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true, // This is important for sending cookies with cross-origin requests
});

export default instance;
