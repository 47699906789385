import React from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api/axiosConfig";

const Navigation = ({ caseNumber, user }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.get("/logout");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <nav className="bg-blue-600 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <ul className="flex space-x-6">
          <li>
            <Link to="/" className="hover:text-blue-200 transition duration-300">Home</Link>
          </li>
          <li>
            {caseNumber && (
              <Link to={`/case/${caseNumber}`} className="hover:text-blue-200 transition duration-300">Case {caseNumber}</Link>
            )}
          </li>
        </ul>
        <div className="flex items-center space-x-4">
          {user ? (
            <>
              <span className="text-blue-200">Welcome, {user.email}</span>
              <button 
                onClick={handleLogout}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" className="hover:text-blue-200 transition duration-300">Login</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
