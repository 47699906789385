import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Mail } from "lucide-react";
import EmailAccordion from "./EmailAccordion";

const OriginalEmail = ({ emailMessages }) => {
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleSummaryItemClick = (event) => {
      const { emailId, lineId } = event.detail;
      setHighlightedItem({ emailId, lineId });
      setIsExpanded(true);
    };

    document.addEventListener("summaryItemClicked", handleSummaryItemClick);

    return () => {
      document.removeEventListener(
        "summaryItemClicked",
        handleSummaryItemClick
      );
    };
  }, []);

  useEffect(() => {
    if (isExpanded && highlightedItem && containerRef.current) {
      const element = containerRef.current.querySelector(
        `#line-${highlightedItem.emailId}-${highlightedItem.lineId}`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [highlightedItem, isExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card className="mb-4 border-2 border-blue-500">
      <CardHeader 
        className="sticky top-0 z-20 bg-white cursor-pointer" 
        onClick={toggleExpand}
      >
        <CardTitle className="flex items-center text-blue-500">
          <Mail className="mr-2" />
          Original Email Thread
          <span className="ml-2">{isExpanded ? '▼' : '▶'}</span>
        </CardTitle>
      </CardHeader>
      {isExpanded && (
        <CardContent className="p-0 h-[400px] overflow-y-auto" ref={containerRef}>
          <EmailAccordion messages={emailMessages} />
        </CardContent>
      )}
    </Card>
  );
};

export default OriginalEmail;
