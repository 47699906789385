import React from 'react';

const ActionItems = ({ actionItems, handleItemClick, loading }) => {
  if (loading) {
    return <div>Loading action items...</div>;
  }

  if (!actionItems || actionItems.length === 0) {
    return <div>No action items found.</div>;
  }

  return (
    <div>
      <ul className="list-none pl-5">
        {actionItems.map((item, index) => (
          <li 
            key={index} 
            onClick={() => handleItemClick('action_item', item.email_id, item.line_id.toString())}
            className="cursor-pointer group relative pl-10 mb-2"
          >
            <span className="absolute left-0 top-0 text-5xl leading-none group-hover:text-blue-500">•</span>
            <div>{item.content}</div>
            <div className="text-sm text-gray-500">
              Assignee: {item.assignee.name} ({item.assignee.role})
            </div>
            <div className="text-sm text-gray-500">
              Requester: {item.requester.name} ({item.requester.role})
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActionItems;
